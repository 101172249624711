import React from "react";
import UsersList from "./UsersList";
import UserList2 from "./usersList/UsersLists2";

function Users() {
  return (
    <div>
      {/* <UsersList /> */}
      <UserList2 />
    </div>
  );
}

export default Users;
