import React from "react";
import delivererPic from "../../images/victoria-unsplash.jpg";
import driverPic from "../../images/delivery.jpg";
import "./Services.css";
import { useInView } from "react-intersection-observer";

function Services() {
  // react-intersection-observer:
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.4,
  });

  const ServicesContent = () => {
    return (
      <section className="services">
        {/* <section className="services"> */}
        <div className="services-title">
          Safety, efficiency and Trust are our assets
        </div>
        <div className="content">
          {/* ***************  driver   ************** */}
          <div className="driver">
            <div>
              <img className="content-img" src={driverPic} alt="driver-img" />
            </div>
            <div className="driver-content">
              <h5 className="content-title">Driver services and delivery</h5>
              <p className="content-paragraph">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet,
                nesciunt molestiae ex inventore quibusdam id architecto quos
                cupiditate nobis magnam eum voluptatem quas quis obcaecati dolor
                vero veritatis similique alias.
              </p>
            </div>
          </div>

          {/* ***************  user   ************** */}
          <div className="user">
            <div className="user-img">
              <img className="content-img" src={delivererPic} alt="user-img" />
            </div>
            <div className="user-content">
              <h5 className="content-title">User services and delivery</h5>
              <p className="content-paragraph">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet,
                nesciunt molestiae ex inventore quibusdam id architecto quos
                cupiditate nobis magnam eum voluptatem quas quis obcaecati dolor
                vero veritatis similique alias.
              </p>
            </div>
          </div>
        </div>
        {/* </section> */}
      </section>
    );
  };

  return (
    <div className={inView ? "box box--zoom" : "box"} ref={ref}>
      <ServicesContent />
    </div>
  );
}

export default Services;
