import React, { useState } from "react";
// import * as React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import "./DriverMap.css";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { checkAvailability } from "../../redux/driverSlice";
import { url } from "../../api/api";

// ***************************************

// second MUI style
const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

// **********************************   Actual component  **********************
function Switcher() {
  // switcher local state
  const [available, setAvailable] = useState(false);

  // redux prep
  // const { driverAvailability } = useSelector((state) => state.driver);
  // const dispatch = useDispatch();

  const id = localStorage.getItem("id");

  // console.log("available state:", available);

  // console.log("driverAvailability:", driverAvailability);

  // *****************************     Axios  (Working)   ***************************

  const handleDriver = async () => {
    await axios
      .put(`${url}/api/driver/availability/${id}`, { availability: available })
      .then((res) => console.log(res.data.data))
      .catch((err) => console.log(err));
    console.log("axios is working !");
  };

  // ***********************************************************************
  return (
    <div>
      <FormGroup>
        <FormControlLabel
          control={
            <Android12Switch
              name="availability"
              onChange={(e) => {
                setAvailable((e.target.available = !available));
              }}
              onClick={() => handleDriver()} // it's working
              // onClick={() =>
              //   dispatch(checkAvailability())
              // }
              // defaultChecked
            />
          }
          label="Availability"
        />

        {/* <Stack direction="row" spacing={1} alignItems="center">
          <Typography>Off</Typography>
          <AntSwitch
            defaultChecked
            inputProps={{ "aria-label": "ant design" }}
          />
          <Typography>On</Typography>
        </Stack> */}
      </FormGroup>
    </div>
  );
}

export default Switcher;
