import React from "react";
import {
  Typography,
  AppBar,
  Toolbar,
  Button,
  Divider,
  Box,
  Tooltip,
  IconButton,
  Avatar,
  Modal,
} from "@mui/material";
import { Link } from "react-router-dom";
import "./Nav2.css";

// *******************    Navbar : Start    ***********************
const Navbar = () => {
  const LandingPageNav = (
    <Toolbar className="client-navbar">
      <Typography
        id="navbar-title"
        variant="h6"
        component="div"
        sx={{
          flexGrow: 0,
          fontFamily: "Roboto Condensed, sans-serif",
        }}
      >
        TakeMyLuggage
      </Typography>

      <Box sx={{ flexGrow: 1 }} />

      <Button color="inherit">
        <Link
          style={{
            fontFamily: "Roboto Condensed, sans-serif",
            textDecoration: "none",
            color: "whitesmoke",
          }}
          to="/features"
        >
          Features
        </Link>
      </Button>

      <Button color="inherit">
        <Link
          style={{
            fontFamily: "Roboto Condensed, sans-serif",
            textDecoration: "none",
            color: "whitesmoke",
          }}
          to="/services"
        >
          Services
        </Link>
      </Button>

      <Button color="inherit">
        <Link
          style={{
            fontFamily: "Roboto Condensed, sans-serif",
            textDecoration: "none",
            color: "whitesmoke",
          }}
          to="/login"
        >
          Sign In
        </Link>
      </Button>

      <Button color="inherit">
        <Link
          style={{
            fontFamily: "Roboto Condensed, sans-serif",
            textDecoration: "none",
            color: "whitesmoke",
          }}
          to="/register"
        >
          Sign Up
        </Link>
      </Button>
    </Toolbar>
  );
  // *******************    Navbar : End    ***********************

  return (
    <AppBar
      position="fixed"
      // sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      {LandingPageNav}
    </AppBar>
  );
};

export default Navbar;
